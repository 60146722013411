<template>
  <!-- 打印故障 -->
  <div class="printfail">
    <se-return></se-return>
    <div class="fl-x-c f-color1 sz-36  fw-bold pgt-160 pgb-80">打印故障</div>
    <div class="fl-x-c">
      <img class="w-180 h-180" src="@/assets/img/故障@2x.png" alt="" />
    </div>
    <div class="fl-x-c mgt-20 mgb-34">
      <div class="w-286 sz-24 fw-400 f-color5 l-h1">
        抱歉，请咨询身边工作人员 或到附近终端继续打印
      </div>
    </div>
    <div class="fl-x-c">
      <img class="w-45 h-40" src="@/assets/img/客服@2x.png" alt="" />
      <span class="sz-30 fw-400 f-color1 mgl-15 mgr-25">400-1165-2678</span>
      <img class="w-52 h-50" src="@/assets/img/电  话 (1) (1)@2x.png" alt="" />
    </div>
    <se-footer></se-footer>
  </div>
</template>

<script>
import Return from "@/components/return";
import Footer from "@/components/footer";

export default {
  components: {
    "se-return": Return,
    "se-footer": Footer,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
