<template>
  <div class="return fl-x-r" @click="$router.go(-1)">
    <i class="el-icon-arrow-left f-color0 sz-30 fw-500"></i>
    <div class="sz-30 f-color0 fw-500 r-text">返回</div>
    <div class="sz-22 f-color0 fw-400 r-right">{{ seconds }}秒</div>
  </div>
</template>

<script>
const second = 30;
export default {
  props: {
    second: {
      type: Number,
      default: () => second,
    },
  },
  data() {
    return {
      timeOut: "",
      seconds: "",
    };
  },
  mounted() {
    this.seconds = this.second; //不能直接改变父组件传过来的值
    this._setInter();
  },
  methods: {
    _setInter() {
      this.timeOut = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          clearInterval(this.timeOut);
          this.$router.push({ path: "/" });
        }
      }, 1000);
    },
  },
  destroyed() {
    clearInterval(this.timeOut);
  },
};
</script>

<style lang="scss" scoped>
.return {
  width: 170px;
  height: 60px;
  background: #24dadb;
  border-radius: 0px 30px 30px 0px;
  position: fixed;
  top: 20px;
  left: 0;
  line-height: 60px;
  .r-text {
    margin: 0 8px;
  }
  .r-right {
    margin-right: 4px;
  }
}
</style>
