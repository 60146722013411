<template>
  <div class="bottom sz-14 f-color9 fw-400">终端所有权归属容联科技</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.bottom {
  width: 100%;
  position: fixed;
  bottom: 15px;
  text-align: center;
}
</style>
